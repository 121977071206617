



import { Component, Vue } from 'vue-property-decorator';
import Breadcrumb from '@/components/contents/breadcrumb/Breadcrumb.vue';

@Component({
  components: {
    Breadcrumb,
  },
})
export default class Suisho extends Vue {
  private breadcrumb: BreadcrumbArr = [
    {
      title: 'HOME',
      link: '/',
    },
    {
      title: '推奨環境',
      link: '',
    },
  ];
}
